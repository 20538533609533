@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-15vw sm:text-7vw lg:text-7xl xl:text-title font-extrabold;
  }
  h2 {
    @apply text-h2 font-bold;
  }
  h3 {
    @apply text-2xl lg:text-h3 font-bold leading-normal;
  }
  h4 {
    @apply text-lg lg:text-2xl font-bold;
  }
  h5 {
    @apply text-base xl:text-xl font-semibold;
  }
  p {
    @apply font-normal lg:text-p text-sm;
  }
}
@font-face {
  font-family: "Mulish";
  src: url(./assets/fonts/Mulish-Regular.ttf);
}
@font-face {
  font-family: "Big Shoulders Display";
  src: url(./assets/fonts/BigShouldersDisplay-Regular.ttf);
}

html {
  @apply text-black;
  font-size: 0.875rem;
  scroll-padding-top: 65px;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

#linear-background {
  background-image: linear-gradient(
    #d6d0c3 43%,
    #f3efe7 43% 72.5%,
    #e5e2da 72.5%
  );
}

@media (min-width: 1024px) {
  #image-background {
    background-image: url("assets/images/capa/Feira.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 55% 100%;
  }

  #linear-background {
    background-color: #f3efe7;
    background-image: 
    linear-gradient(to left, transparent 50%, #d6d0c3 50%),
    linear-gradient(to left, transparent 50%, #f3efe7 50%),
    linear-gradient(to bottom, transparent 50%, #e5e2da 50%),
    linear-gradient(to bottom, transparent 50%, #f3efe7 50%);
    
  }
}
